import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import Header from './header'
import './layout.css'

let year = new Date().getFullYear();

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
           // { name: 'description', content: 'Sample' },
          //  { name: 'keywords', content: 'sample, something' },
          ]}
        >
          <html lang="en" />
        </Helmet>

        <div id="wrap">
          
          <Header siteTitle={data.site.siteMetadata.title} />
          
          
      		<div id="content-wrap">
            <div id="main">
              {children}
            </div>
          </div>
                
          
          <div id="footer">
            
            <p>&copy; <span>{year}</span> <strong>Lawrence Hoffman, CPA, PC</strong><br />
            &copy; {year} <strong>Lawall Service Corp.</strong></p>
              
          </div>	

      
      </div>        
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
