import React from 'react'
import { Link } from 'gatsby'
import logo from "images/lhcpapclogo.png"

const Header = ({ siteTitle }) => (
  <div>
  <div id="header">			
              
      <h1 id="logo-text"><Link to="/"><img src={logo} style={{border: "none"}} alt={siteTitle} /></Link></h1>		
      
      
      <div id="header-links">
      <p>Phone: <span style={{fontWeight: "bold"}}>(516) 625-9051</span>
      </p>		
    </div>		
            
  </div>
    
  <div  id="menu">
    <ul>
      <li><Link to="/">Home</Link></li>
      <li><Link to="/computer-consulting/">Computer Consulting by Lawall</Link></li>
      <li className="last"><Link to="/contact/">Contact Us</Link></li>
    </ul>
  </div>	
  </div>
)

export default Header
